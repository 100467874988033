export default `
    @font-face {
        font-family: "Din-Round";
        font-style: normal;
        font-weight: 500;
        src: url(/assets/fonts/din-round-500.woff2) format("woff2");
    }

    @font-face {
        font-family: "Din-Round";
        font-style: normal;
        font-weight: 700;
        src: url(/assets/fonts/din-round-700.woff2) format("woff2");
    }

    @font-face {
        font-family: "Din-Round";
        font-style: normal;
        font-weight: 900;
        src: url(/assets/fonts/din-round-900.woff2) format("woff2");
    }
`;
