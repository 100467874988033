import styled from "styled-components";

export default ({ size = "md", stroke = 400, children, ...rest }) => {
  return (
    <Wrapper $size={size} $stoke={stroke} {...rest}>
      {children}
    </Wrapper>
  );
};

const getSize = (size) => {
  switch (size) {
    case "sm":
      return "1.25rem";
    case "md":
      return "1.5rem";
    case "lg":
      return "2rem";
    case "xl":
      return "3.5rem";
  }
};

const Wrapper = styled.div`
  aspect-ratio: 1;
  width: ${(props) => getSize(props.$size)};
  --stroke-color: transparent;
  --stroke-width: ${(p) => parseInt(p.theme.stroke[400]) * 2}px;
`;
