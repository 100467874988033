import { create } from "zustand";
import { getCookie } from "../../libraries/cookies";
import { persist, createJSONStorage } from "zustand/middleware";

const initial = {
  token: "",
  tag: "",
  lang: "",
  yearOfBirth: -1,
  modPower: -1,
  name: "",
  coins: -1,
  gems: -1,
  medals: {},
  items: [],
  customization: { skin: 10000, bottom: 20000, top: 30000 },
  ownedCustomizations: [],
  games: {},
};

export const useUserStore = create(
  persist(
    (set, get) => ({
      ...initial,
      updateGame: (slug, data) => {
        const token = getCookie("gamly-token");
        const { games } = get();
        if (games.token !== token) set({ games: { token } });
        set((state) => ({ games: { ...state.games, [slug]: { ...state.games[slug], ...data } } }));
      },
      getGame: (slug) => {
        const { games } = get();
        const token = getCookie("gamly-token");
        if (token && games.token !== token) {
          set({ games: { token } });
          return;
        }
        return games[slug];
      },
      reset: () => set(initial),
    }),
    {
      name: "gamly:userStore",
      storage: createJSONStorage(() => sessionStorage),
      partialize: (s) => ({ games: s.games }),
    },
  ),
);

export const saveCustomization = async () => {
  try {
    const token = getCookie("gamly-token");
    if (!token) throw new Error("No token found");
    const { customization } = useUserStore.getState();

    const res = await fetch(`${import.meta.env.VITE_SERVER}/locker/saveCustomization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ customization }),
    });

    if (!res.ok) throw new Error("Unknown error occurred");
    const data = await res.json();

    if (data.error?.customization) {
      useUserStore.setState({ customization: data.error.customization });
      //TBD: show error message
    }
  } catch (error) {
    console.error(error);
  }
};
