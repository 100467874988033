export default {
  background: "rgb(20, 30, 40)",

  text: {
    500: "#B1B4B8",
    400: "#fff",
  },

  grayscale: {
    100: "rgb(20, 30, 40)",
    200: "rgb(35, 46, 57)",
    300: "rgb(65, 75, 85)",
    400: "rgb(95, 105, 115)",
    500: "rgb(130, 140, 150)",
    600: "rgb(185, 190, 195)",
    700: "rgb(210, 215, 220)",
    800: "rgb(230, 233, 237)",
    900: "rgb(255, 255, 255)",
  },
};
