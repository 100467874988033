import Loading from "./Loading";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { useTheme } from "styled-components";
import themeDark from "../styles/variables/dark";
import themeLight from "../styles/variables/light";

export default ({
  children,
  variant = "accentBlue",
  size = "md",
  shine = false,
  loading = false,
  disabled = false,
  border = false,
  shadow,
  ...rest
}) => {
  const theme = useTheme();

  const variants = {
    accentYellow: {
      background: theme.accentYellow[400],
      color: themeLight.text[400],
      elevationColor: theme.elevationColor || "rgba(0, 0, 0, 0.15)",
      hoverBrightness: 1.1,
    },
    accentBlue: {
      background: theme.accentBlue[400],
      color: themeDark.text[400],
      elevationColor: theme.elevationColor || "rgba(0, 0, 0, 0.2)",
      hoverBrightness: 1.1,
    },
    theme: {
      background: theme.background,
      color: theme.text[400],
      elevationColor: theme.elevationColor || theme.grayscale[200],
      hoverBrightness: 1.1,
    },
    dark: {
      background: themeDark.background,
      color: themeDark.text[400],
      elevationColor: theme.elevationColor || themeDark.grayscale[200],
      hoverBrightness: 1.1,
    },
    light: {
      background: themeLight.background,
      color: themeLight.text[400],
      elevationColor: theme.elevationColor || themeLight.grayscale[200],
      hoverBrightness: 0.925,
    },
    yellow: {
      background: theme.yellow[400],
      color: themeLight.text[400],
      elevationColor: theme.elevationColor || "rgba(0, 0, 0, 0.25)",
      hoverBrightness: 1.1,
    },
    red: {
      background: theme.red[400],
      color: theme.white[700],
      elevationColor: theme.elevationColor || "rgba(0, 0, 0, 0.2)",
      hoverBrightness: 1.25,
    },
    green: {
      background: theme.green[400],
      color: theme.white[700],
      elevationColor: theme.elevationColor || "rgba(0, 0, 0, 0.25)",
      hoverBrightness: 1.1,
    },
    game: {
      background: theme.primary ? theme.primary[400] : theme.light,
      color: theme.text[400],
      elevationColor: theme.elevationColor || "rgba(0, 0, 0, 0.25)",
      hoverBrightness: 1.1,
    },
  };

  return (
    <ThemeProvider theme={variants[variant]}>
      <Wrapper $size={size} $hover={!disabled} $shadow={shadow} {...rest}>
        <Button $size={size} $border={border} $shine={shine} disabled={disabled}>
          {shine && <Shine />}
          {loading ? <Loading size="xs" /> : children}
        </Button>
      </Wrapper>
    </ThemeProvider>
  );
};

const getHeight = (size) => {
  switch (size) {
    case "sm":
      return "2rem";
    case "md":
      return "2.5rem";
    case "lg":
      return "3rem";
  }
};

const getFontSize = (size) => {
  switch (size) {
    case "lg":
      return "1.125rem";
    case "sm":
      return "0.875rem";
    default:
      return "1rem";
  }
};

const getPadding = (size) => {
  switch (size) {
    case "sm":
      return "1rem";
    case "md":
      return "1.5rem";
    case "lg":
      return "2rem";
  }
};

const Button = styled.button`
  position: relative;
  transition:
    background-color 0.1s ease-in-out,
    color 0.1s ease-in-out,
    border-bottom 0.05s ease-in-out;
  border-radius: ${(p) => p.theme.radius[400]};
  background-color: ${(p) => p.theme.background};
  border-color: ${(p) => p.theme.elevationColor};
  border-style: solid;
  height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  padding: 0 ${(p) => getPadding(p.$size)};
  border-width: ${(p) =>
    p.$border
      ? `${parseInt(p.theme.stroke[400])}px ${parseInt(p.theme.stroke[400])}px ${parseInt(p.theme.stroke[400]) + parseInt(p.theme.elevation[400])}px ${parseInt(p.theme.stroke[400])}px`
      : `0 0 ${parseInt(p.theme.stroke[400]) + parseInt(p.theme.elevation[400])}px 0`};

  font-size: ${(p) => getFontSize(p.$size)};

  ${(p) => p.$shine && "overflow: hidden;"}
`;

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  font-weight: 700;
  cursor: default;
  transition:
    filter 0.1s ease-in-out,
    padding-top 0.05s ease-in-out;
  border-radius: ${(p) => p.theme.radius[400]};
  color: ${(p) => p.theme.color};
  height: ${(p) => getHeight(p.$size)};
  ${(p) => p.$shadow && `box-shadow: ${p.theme.shadow};`}

  &:active {
    padding-top: 3px;

    ${Button} {
      border-bottom: ${(p) => p.theme.stroke[400]} solid ${(p) => p.theme.elevationColor};
    }
  }

  ${(p) => p.$size === "square" && "aspect-ratio: 1;"}

  ${(p) =>
    p.$hover &&
    `
      cursor: pointer;

        body.no-touch &:hover {
          filter: brightness(${p.theme.hoverBrightness});
        }
    `}
`;

const Shine = styled.div`
  height: 300%;
  width: 1.5rem;
  position: absolute;
  bottom: -50%;
  left: 0;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.2);
  transform: rotate(20deg);
  animation: moveShine 4s infinite linear;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -1rem;
    width: 0.75rem;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
  }

  @keyframes moveShine {
    0% {
      left: -6rem;
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 0;
      left: calc(100% + 3rem);
    }
    100% {
      left: calc(100% + 3rem);
    }
  }
`;
